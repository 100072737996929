// src/pages/jokes.js

import React from 'react';

export default class SpeedIndex extends React.Component {
    _renderSpeeds = () => {
        const speeds = this.props.data.allSpeedIndex.edges;
        return speeds.map(speed => {
            return (
                <li key={speed.node.id}>
                    <p>{speed.node.url} -
                    {speed.node.score}</p>
                </li>
            );
        });
    };

    render() {
        return <ul className="Jokes">{this._renderSpeeds()}</ul>;
    }
}

export const query = graphql`
  query SpeedQuery {
    allSpeedIndex {
      edges {
        node {
          id
          score
          url
        }
      }
    }
  }
`;
